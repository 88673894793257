<template>
    <kinesis-container event="move">
      <kinesis-element  :strength="0.1" type="scale">
        <v-card
            flat
            class="ma-6 ma-md-3 rounded-lg"
            color="black"
            min-width="250px"
        >
          <v-img
              :src="img(entry.img)"
              :aspect-ratio="ratio"
              gradient="to top, rgba(30,30,30,1) 0%, rgba(0,0,0,0) 20%"

          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container
                style="height: 100%; width: 100%"
                class="d-flex flex-column pa-0"
            >
              <v-row
                  justify="start"
                  class="pa-4"
                  style="position: absolute"
              >
                <v-chip
                    v-for="(r, i) in entry.responsability"
                    :key="`s-${i}`"
                    class="ma-1"
                    :color="getColor(r)"
                    text-color="white"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>{{getIcon(r)}}</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{$t(`executives.${r}`)}}</span>
                  </v-tooltip>
                </v-chip>
              </v-row>
                <v-expansion-panels
                    dark
                    flat
                    class="mt-auto"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                        expand-icon="mdi-chevron-up"
                        color="rgba(23,255,255,0.0)"
                        style="position: absolute;bottom: 0px;overflow: clip"
                    >
                      <v-card-title
                          class="text--white title text-sm-subtitle-1 text-lg-h6 text-no-wrap logo pa-2"
                          style="white-space:break-spaces;word-break: break-word"
                      >
                        {{fullName}}
                      </v-card-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                        class="pb-10 pt-5"
                    >
                      <v-row justify="center">
                        <v-chip
                            class="logoLight pa-2 text-center"
                            color="accent"
                            text-color="white"
                            style="max-width: 200px;"
                        >
                          {{ entry.position }}
                        </v-chip>
                        <v-card-text
                            class="text--white text-center"
                        >
                          {{entry.text}}
                        </v-card-text>
                      </v-row>
                      <v-card-actions
                          class="d-flex justify-center"
                      >
                        <v-btn
                            tonal
                            class="logo"
                            rounded="xl"
                            v-text="entry.email"
                            :href="`mailto:${entry.email}`"
                            target=""
                        />
                      </v-card-actions>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
            </v-container>
          </v-img>
        </v-card>
      </kinesis-element>
    </kinesis-container>
</template>

<script>
    import i18n from '@//plugins/i18n'
    import{KinesisElement, KinesisContainer} from 'vue-kinesis'

    export default {
        name: "Executives",
        components: {
          KinesisElement,
          KinesisContainer
        },
      data: () => ({
        ratio: 0.8
      }),
        props:{
            entry: {
                type: Object,
                default: () => ({
                  img: {
                    url: ''
                  },
                  firstName: 'Default',
                  lastName: '',
                  position: '',
                  description:'',
                  email: 'info@ktt01.de'
                })
            }
        },
        methods: {
        img(img){
          return img == null ? undefined : img.url
        },
          toggleRatio(){
            if(this.ratio === 0.75) this.ratio = 1.6
            else this.ratio = 0.75
          },
          getIcon (type) {
            switch (type) {
              case 'Vorstand':
                return 'mdi-star'
              case 'Back Office':
                return 'mdi-account-multiple'
              case 'aerztliche Betreuung':
                return 'mdi-medical-bag'
              case 'Ligaplanung':
                return 'mdi-calendar-check'
              case 'Ansprechperson Sponsoren':
                return 'mdi-file-edit'
              case 'Social Media':
                return 'mdi-film'
              case 'Mitgliederverwaltung':
                return 'mdi-account-multiple-plus'
            }
          },
          getColor(type){
            switch (type) {
              case 'Finanzen':
                return 'orange'
              case 'Back Office':
                return 'blue-grey darken-1'
              case 'aerztliche Betreuung':
                return 'primary'
              case 'Ligaplanung':
                return 'blue-grey darken-3'
              case 'Ansprechperson Sponsoren':
                return 'cyan'
              case 'Social Media':
                return 'pink'
              case 'Mitgliederverwaltung':
                return 'green darken-3'
              case 'Vorstand':
                return 'secondary'
            }
          },
      },
        computed: {
            fullName () {
                return this.entry.firstName + ' ' + this.entry.lastName
            },
            currentLang () {
                return i18n.locale
            },

        }
    }
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .display-1
    font-family: $logo
  .title
    font-family: $logo
  .text-md-h4
    font-family: $logo

.logoLight
  font-family: $logoLight
</style>

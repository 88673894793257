<template>
  <base-card
      class="pb-5"
  >
    <!--About-Text-->
    <v-container
        :style="$vuetify.breakpoint.mdAndUp ? 'height: 650px' : 'height: 400px'"
        class="d-flex align-center"
    >
      <v-skeleton-loader
          type="paragraph@3"
          :loading="loading"
          class="mt-10"
      >
        <template v-slot:default>
          <v-card-text
              id="about"
              class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto"
              v-html="about"
          >
          </v-card-text>
        </template>
      </v-skeleton-loader>
    </v-container>
    <!--Parralax-->
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 300 : 300"
        :lazy-src="`${require('@/assets/img/pulheim.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/pulheim.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-card-title
                id="about"
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
                class="justify-center text--white text-uppercase logo mt-6 my-3"
            >
              {{$t('about.executives')}}
            </v-card-title>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
    <!--Vorstand-->
    <v-row class="px-lg-10">
      <v-col cols="12" lg="12">

        <!--    <v-row justify="center">
              <template v-for="executive in executives">
                <executives
                    :key="executive.sys.id"
                    :entry="executive"
                />
              </template>
            </v-row>-->
        <vue-slick-carousel
            v-if="executives.length !== 0"
            v-bind="sliderSettings"
        >
          <executives
              v-for="executive in executives"
              :key="executive.sys.id"
              :entry="executive"
          />
        </vue-slick-carousel>
      </v-col>
      <v-col cols="12" lg="12">
      </v-col>
    </v-row>
    <!--Timeline-->
    <v-container
        fluid
        :style="[$vuetify.breakpoint.mdAndUp ? 'width: 40%' : 'width: 60%']"
        class="justify-md-center mx-auto"
    >
      <v-timeline
          :dense="!$vuetify.breakpoint.mdAndUp"
          :reverse="!$vuetify.breakpoint.mdAndUp"
      >
        <v-timeline-item
            v-for="(entry, i) in timeline"
            :key="i"
            :icon="`mdi-${entry.icon}`"
            size="small"
        >
          <span
              v-if="$vuetify.breakpoint.mdAndUp"
              slot="opposite"
              class="justify-center text-uppercase logoLight text-subtitle-1"
          >
            {{entry.date}}
          </span>
          <v-card class="ml-3">
            <v-card-title
                v-if="$vuetify.breakpoint.mdAndUp"
                class="justify-center text-uppercase logoLight text-subtitle-1"
            >{{entry.title}}</v-card-title>
            <v-card-text
                v-if="$vuetify.breakpoint.mdAndUp"
                class="text-xs-center text-lg-justify text-lg-subtitle-1 text-sm-subtitle-2 font-weight-light mx-auto"
                v-html="entry.text"
            >
            </v-card-text>
            <v-expansion-panels
                v-if="!$vuetify.breakpoint.mdAndUp"
                style="color: rgba(0,0,0,0.6)"
                flat
                class="mt-auto"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                    expand-icon="mdi-chevron-down"
                >
                  <v-card-title
                      class="text-uppercase logoLight text-subtitle-1"
                      style="white-space:break-spaces;word-break: break-word"
                  ><b>{{entry.date}}:</b> {{entry.title}}</v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text
                      class="text-xs-center text-lg-justify text-lg-subtitle-1 text-sm-subtitle-2 font-weight-light mx-auto"
                      v-html="entry.text"
                  >
                  </v-card-text>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <!--Parallax-->
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
        :lazy-src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-col
                class="font-weight-bold white--text text-uppercase headline logo"
            >
              #JEMEINSAM
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
  </base-card>
</template>

<script>
import Executives from "@/components/about/Executives"
import {fetchData, query_EXECUTIVES, query_ABOUT, query_TIMELINE} from '@//utils/api'
import i18n from '@//plugins/i18n'
import showdown from 'showdown'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    Executives,
    VueSlickCarousel
  },
  data: ()=>({
    loading: true,
    about: {},
    timeline: {},
    executives: [],
    medicalSupport: [],
    backOffice: [],
    sliderSettings: {
      centerMode: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 0,
            initialSlide: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }),
  methods: {
    getAbout: async () => {
      const query = query_ABOUT(i18n.locale)
      return await fetchData(query)
    },
    getExecutives: async () => {
      const query = query_EXECUTIVES(i18n.locale)
      return fetchData(query, 'executivesCollection')
    },
    getTimeline: async () => {
      const query = query_TIMELINE(i18n.locale)
      return await fetchData(query, 'infoTextCollection')
    },
  },
  async created() {
    const about = await this.getAbout()
    const converter = new showdown.Converter()
    this.about = converter.makeHtml(about.textContent.text)
    this.loading =false
    this.executives = await this.getExecutives()
    this.timeline = await  this.getTimeline()

    this.$eventHub.$on('locale-changed', async()=>{
      this.loading =true
      const about = await this.getAbout()
      const converter = new showdown.Converter()
      this.about = converter.makeHtml(about.textContent.text)
      this.loading =false
      this.executives = await this.getExecutives()
      this.timeline = await  this.getTimeline()
    })
  },
}
</script>
